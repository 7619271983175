// Libraries
import { useState } from 'react';
import { Title, Image, Button, Stack, SegmentedControl, Tabs } from '@mantine/core';

// Types
import { getMetricTypeLogo, IMetricConfiguration } from '@/types/metrics';

// Styles
import styles from './MetricConfiguration.module.css';
import { FiPlusCircle } from 'react-icons/fi';


// Interfaces
interface MetricSuggestionsProps {
    onCreate: (newMetricConfiguration: IMetricConfiguration) => void;
}

interface ISuggestions {
    [key: string]: Partial<IMetricConfiguration>[];
}

const suggestions: ISuggestions = {
    'health' : [
        {
            'name': 'Exercise',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Gym',
            'metricType': 'Checkmark',
        },
        {
            'name': "Mindfulness",
            'metricType': 'Checkmark',
        },
        {
            'name': 'Running',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Steps',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Yoga',
            'metricType': 'Checkmark',
        },
        {
            'name': "Barry's",
            'metricType': 'Checkmark',
        },
    ],
    'hobbies' : [
        {
            'name': 'Cooking',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Reading',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Tennis',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Coding',
            'metricType': 'Checkmark',
        }
    ],
    'productivity' : [
        {
            'name': 'Chores',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Work',
            'metricType': 'Emoji-Battery',
        },
        {
            'name': 'Budget',
            'metricType': 'Checkmark',
        },
        {
            'name': 'Spending',
            'metricType': 'Checkmark',
        },
    ]
}

export function MetricSuggestions({ onCreate }: MetricSuggestionsProps) {

    // Hooks
    const [activeCategory, setActiveCategory] = useState<string>('health');
    const [activeSuggestions, setActiveSuggestions] = useState<Partial<IMetricConfiguration>[]>(suggestions[activeCategory]);


    const onUpdateCategory = (newCategory: string | null) => {

        if(newCategory !== null) {
            setActiveCategory(newCategory);
            setActiveSuggestions(suggestions[newCategory]);
        }
    }

    const onSaveSuggestion = (suggestion: Partial<IMetricConfiguration>) => {

        const newMetricConfiguration: IMetricConfiguration = {
            name: suggestion.name || '',
            metricType: suggestion.metricType || 'Checkmark',
            order: 0,
            includeInTotal: true,
            locked: false,
            active: true
        };

        onCreate(newMetricConfiguration);
    }


    const changeSuggestionMetricType = (value: string, index: number) => {

        // 1. Make a shallow copy of the items
        let items = [...activeSuggestions];
        // 2. Make a shallow copy of the item you want to mutate
        let item = {...items[index]};
        // 3. Replace the property you're intested in
        item.metricType = value;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, 
        //    but that's why we made a copy first
        items[index] = item;

        // 5. Set the state to our new copy
        setActiveSuggestions(items);

    }

    // Render Functions
    const renderSuggestion = (suggestion: Partial<IMetricConfiguration>, index: number) => {

        return (
            <div key={index} className={`${styles.metricConfigTile} ${styles.suggestion}`}>
                <div className={styles.front}>
                    {/* suggestion.metricType && <Image src={getMetricTypeLogo(suggestion.metricType)} h='32px' /> 
                    <Divider orientation='vertical' />*/}
                    <Title
                        c={'var(--blue-shade)'}
                        fz={{ base: '18px', md: '22px' }}
                        ff='Zilla Slab'
                        fw={600}
                        tt='capitalize'
                    >
                        {suggestion.name}
                    </Title>
                </div>
                <div className={styles.back}>
                <SegmentedControl size='xs' value={suggestion.metricType} withItemsBorders={false} onChange={(value) => changeSuggestionMetricType(value, index)}
                    data={[
                        {
                            value: 'Checkmark',
                            label: (<Image src={getMetricTypeLogo('Checkmark')} h={20} w={20} fit='contain' />),
                        },
                        {
                            value: 'Emoji-Battery',
                            label: (<Image src={getMetricTypeLogo('Emoji-Battery')} h={20} w={20} fit='contain' />),
                        },
                        {
                            value: 'Emoji-Triangle',
                            label: (<Image src={getMetricTypeLogo('Emoji-Triangle')} h={20} w={20} fit='contain' />),
                        },
                    ]}
                    />
                <Button
                    onClick={onSaveSuggestion.bind(index, suggestion)}
                    bg={'var(--blue-shade-max)'}
                    h='32px'
                    ff='Zilla Slab'
                    fw={600}
                    fz='14px'
                    radius='sm'
                    leftSection={<FiPlusCircle size={16} color='#7fbbbd' />}
                    px={12}
                >
                    Add
                </Button>
                </div>
            </div>
        )
    }

    // Render
    return (
        <Stack gap={15} py={15}>
            <Tabs defaultValue={activeCategory} onChange={onUpdateCategory} color='var(--blue-shade-max)'>
            <Tabs.List>
            {
                Object.keys(suggestions).map((suggestionType, index) => (
                    <Tabs.Tab value={suggestionType} key={index}>
                        <Title tt='uppercase' fz={12} fw={600} lts='0.5px' ff='Josefin Sans Variable'>{suggestionType}</Title>
                    </Tabs.Tab>
                ))
            }
            </Tabs.List>
            {
                Object.keys(suggestions).map((suggestionType, index) => (
                    <Tabs.Panel value={suggestionType} key={index} mah='300px' style={{overflowY: 'scroll', paddingRight: '10px'}} pt={10}>
                        {
                            activeSuggestions.map((suggestion, index) => (
                                renderSuggestion(suggestion, index)
                            ))
                        }
                    </Tabs.Panel>
                ))
            }

            </Tabs>

        </Stack>
    );
}

export default MetricSuggestions;

// Libraries
import { useEffect, useState } from 'react';
import { Container, Title, Stack, Group, Button, Card, SegmentedControl } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

// Components
import { MetricConfigurationTile } from './MetricConfigurationTile';
import { MetricConfigurationEditor } from './MetricConfigurationEditor';
import { MetricSuggestions } from './MetricSuggestions';
import { IsPending } from '@/components/isPending/IsPending';
import { ErrorBox } from '@/components/errorbox/ErrorBox';

// Api
import { getUserProfile, updateUserProfile } from '@/api/userProfile.api';

// Auth
import { useUser } from '@/auth/auth.store.ts';

// Types
import { IMetricConfiguration } from '@/types/metrics';

// Styles
import styles from './MetricConfiguration.module.css';
import { UserProfile } from '@/types/auth.types';

export function MetricConfiguration() {

    const newMetric: IMetricConfiguration = {
        name: '',
        metricType: 'Checkmark',
        order: 0,
        includeInTotal: true,
        locked: false,
        active: true
    };

    // Hooks
    const user = useUser();
    const userId = user ? user._id : '';
    const queryClient = useQueryClient();
    const { width } = useViewportSize();
    const [editing, setEditing] = useState<IMetricConfiguration>(newMetric);
    const [editingType, setEditingType] = useState<string>('nothing'); // nothing, create, edit, suggestions

    const { isPending, isError, data: userProfile, error } = useQuery({
        queryKey: ['user-profile', userId],
        queryFn: () => getUserProfile(userId),
        retry: 1
    });

    /*useEffect(() => {
        if(width > 992 && editingType === 'nothing')
            setEditingType('create');
    }, [width]);*/

    useEffect(() => {

        if(editingType === 'create') {
            const newMetric: IMetricConfiguration = {
                name: '',
                metricType: 'Checkmark',
                order: userProfile.metricConfigurations.length || 0,
                includeInTotal: true,
                locked: false,
                active: true
            };
            setEditing(newMetric);
        }

    }, [editingType]);

    /*const beginEditingNewMetric = () => {
        const newMetric: IMetricConfiguration = {
            name: '',
            metricType: 'Checkmark',
            order: userProfile.metricConfigurations.length || 0,
            includeInTotal: true,
            locked: false,
            active: true
        };
        setEditing(newMetric);
        setEditingType('create');
    };*/

    const cancelEditing = () => {
        setEditing(newMetric);
        setEditingType('nothing');
    };

    const startEditing = (metric: IMetricConfiguration) => {
        setEditing(metric);
        setEditingType('edit');
    };

    const updateMutation = useMutation({
        mutationFn: updateUserProfile,
        onSuccess: (updatedUserProfile: UserProfile) => {
            queryClient.setQueryData(['user-profile', userId], updatedUserProfile);
        },
        onError: (error) => {
            console.log(error);
            //todo: handle error
        },
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['user-profile', userId]});
        }
    });

    // Given the updated metric configurations, update the user profile
    const mergeMetricConfigurationsAndUpdate = (updatedConfigurations: IMetricConfiguration[]) => {

        const sortedConfigurations = updatedConfigurations.sort((a: IMetricConfiguration, b: IMetricConfiguration) => a.order - b.order);

        const updatedUserProfile: UserProfile = { 
            ...userProfile,
            metricConfigurations: sortedConfigurations
        };

        updateMutation.mutate(updatedUserProfile);

    }

    // Update a specific metric configuration
    const onCreate = (newMetricConfiguration: IMetricConfiguration) => {

        let updatedConfigurations = userProfile.metricConfigurations;
        newMetricConfiguration.order = updatedConfigurations.length;
        updatedConfigurations.push(newMetricConfiguration);

        mergeMetricConfigurationsAndUpdate(updatedConfigurations);
        
    }


    // Update a specific metric configuration
    const onUpdate = (metricConfiguration: IMetricConfiguration) => {

        let updatedConfigurations = userProfile.metricConfigurations;
        const index = updatedConfigurations.findIndex((mc: IMetricConfiguration) => mc._id === metricConfiguration._id);
        updatedConfigurations[index] = metricConfiguration;

        mergeMetricConfigurationsAndUpdate(updatedConfigurations);
        
    }

    // Delete a specific metric configuration
    const onDelete = (metricConfigurationId: string) => {

        let updatedConfigurations = userProfile.metricConfigurations;
        const index = updatedConfigurations.findIndex((mc: IMetricConfiguration) => mc._id === metricConfigurationId);
        updatedConfigurations.splice(index,1);

        mergeMetricConfigurationsAndUpdate(updatedConfigurations);
        
    }

    // Renders the main tile area
    const renderTileArea = () => {
        if (isPending) {
            return <IsPending text='Loading your Metrics Configurations..' />;
        }
        if (isError) {
            return <ErrorBox text={error.message} />;
        }
        if (!userProfile || userProfile.metricConfigurations.length === 0)
            return <ErrorBox text={'It would seem that you have no metric configurations!'} />;

        return userProfile.metricConfigurations
            .sort((a: IMetricConfiguration, b: IMetricConfiguration) => a.order - b.order)
            .map((metric: IMetricConfiguration) => (
                <MetricConfigurationTile metric={metric} setEditing={startEditing} />
            ));
    };


    const renderProperSection = () => {

        if(editingType === 'suggestions') {
            return (
                <Card
                    shadow='xs'
                    padding='sm'
                    radius='md'
                    w={{ base: '100%', md: '50%' }}
                    bg='#FAF8F5'
                    h='fit-content'>
                    <SegmentedControl withItemsBorders={false} value={editingType} onChange={setEditingType}
                        data={[
                            {
                                value: 'suggestions',
                                label: 'Suggested Metrics',
                            },
                            {
                                value: 'create',
                                label: 'Create My Own',
                            },
                        ]}
                        />
                    <Stack gap={15}>
                    <MetricSuggestions onCreate={onCreate} />
                    </Stack>
                </Card>
            );
        }
        else if(editingType === 'create') {
            return (
                <Card
                    shadow='xs'
                    padding='sm'
                    radius='md'
                    w={{ base: '100%', md: '50%' }}
                    bg='#FAF8F5'
                    h='fit-content'>
                    <SegmentedControl withItemsBorders={false} value={editingType} onChange={setEditingType}
                        data={[
                            {
                                value: 'suggestions',
                                label: 'Suggested Metrics',
                            },
                            {
                                value: 'create',
                                label: 'Create My Own',
                            },
                        ]}
                        />
                    <Stack gap={15}>
                    <MetricConfigurationEditor
                            editingType={editingType}
                            metricConfiguration={editing}
                            isSaving={updateMutation.isPending}
                            onCancel={cancelEditing}
                            onCreate={onCreate}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Stack>
                </Card>
            );
        }
        else if (editingType === 'edit') {
            return (
                <MetricConfigurationEditor
                    editingType={editingType}
                    metricConfiguration={editing}
                    isSaving={updateMutation.isPending}
                    onCancel={cancelEditing}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            )
        }
        else {
            return null;
        }
     }

    // Render

    return (
        <Container size={'lg'} mt={{ base: '10px', md: '20px' }} pb={100}>
            <Group align='center' justify='space-between' w={{ base: '100%', md: '50%' }} mb={10}>
                <Title
                    c={'var(--blue-shade)'}
                    fz={{ base: '24px', xs: '28px', sm: '32px', md: '36px', lg: '48px' }}
                    ff='Zilla Slab'
                    fw={600}
                >
                    My Metrics ({userProfile?.metricConfigurations?.length || 0})
                </Title>
                { editingType === 'nothing' ?
                    <Button
                        onClick={() => setEditingType('suggestions')}
                        bg='#001427'
                        h='34px'
                        ff='Zilla Slab'
                        fw={600}
                        fz='16px'
                        radius='sm'
                        leftSection={<FiPlusCircle size={18} color='#7fbbbd' />}
                        px={12}
                    >
                        Add New
                    </Button> : 
                    <Button
                        onClick={cancelEditing}
                        bg={'var(--medium-tan-tint)'}
                        h='34px'
                        ff='Zilla Slab'
                        fw={600}
                        fz='16px'
                        radius='sm'
                        leftSection={<FiArrowLeft size={18} color='white' />}
                        px={12}
                    >
                        Back
                    </Button>
                }
            </Group>
            <Title
                c={'#827A6E'}
                fz={{ base: '20px', xs: '21px', sm: '24px', md: '26px', lg: '30px' }}
                ff='Zilla Slab'
                fw={400}
                mb={{ base: 20, md: 40 }}
            >
                Configure your custom metrics.
            </Title>

            <div className={styles.orientationSwitch}>
                {width > 992 || (width < 992 && editingType === 'nothing') ? (
                    <Stack
                        w={{ base: '100%', md: '50%' }}
                        mah='520px'
                        h='max-content'
                        style={{ overflowY: 'scroll' }}
                        pb={50}
                    >
                        {renderTileArea()}
                    </Stack>
                ) : null}
                {
                    renderProperSection()
                }
            </div>
        </Container>
    );
}

export default MetricConfiguration;

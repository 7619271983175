// Libraries
import { Title, Badge, Divider, Paper, Image, Button } from '@mantine/core';

// Styles

// Types
import { getMetricTypeLogo, IMetricConfiguration } from '@/types/metrics';

// Styles
import styles from './MetricConfiguration.module.css';

// Interfaces
interface MetricConfigurationTileProps {
    metric: IMetricConfiguration;
    setEditing: (metric: IMetricConfiguration) => void;
}

export function MetricConfigurationTile({ metric, setEditing }: MetricConfigurationTileProps) {
    // Render
    return (
        <Paper
            bg='#FAF8F5'
            p={{ base: 'sm', md: 'md' }}
            radius='md'
            onClick={() => setEditing(metric)}
            key={metric.name}
            className={styles.metricConfigTile}
        >
            <div className={styles.front}>
                <div className={styles.draggableDots}>
                    <div className={styles.column}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={styles.column}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                {<Image src={getMetricTypeLogo(metric.metricType)} h='40px' />}
                <Divider orientation='vertical' />
                <Title
                    c={'var(--blue-shade)'}
                    fz={{ base: '20px', md: '24px' }}
                    ff='Zilla Slab'
                    fw={600}
                >
                    {metric.name}
                </Title>
            </div>

            <div className={styles.back}>
                {metric?.locked ? (
                    <Badge
                        ff='zilla slab'
                        variant='outline'
                        color='var(--blue-shade)'
                        radius='sm'
                        fz='14px'
                        tt='lowercase'
                    >
                        default
                    </Badge>
                ) : (
                    <Button variant='subtle' color='#B5AFA5' size='sm' ff='Zilla Slab' fz='20px'>
                        edit
                    </Button>
                )}
            </div>
        </Paper>
    );
}

export default MetricConfigurationTile;

// Libraries
import { IServerError } from '@/types/errors.types';
import { Paper, Stack, Title, Group } from '@mantine/core';
import { FiAlertOctagon } from 'react-icons/fi';

interface ErrorBoxProps {
    text: string;
    serverError?: IServerError;
}

export function ErrorBox({ text, serverError }: ErrorBoxProps) {

    // errorCode, message, data
    const { statusCode, errorCode } = serverError ?? {};


    return (
        <Paper p='40px' radius='4px' bg='#F2EFE9' w='100%' h='100%' shadow='none' mt={20}>
            <Stack align='center'>
                <Group>
                    <FiAlertOctagon size='36px' color='var(--red-200)' fill='white' />
                    <Title c='var(--red-200)' ff='Zilla Slab' fz='30px' fw={700}>
                        Uh Oh. { statusCode ? `(${statusCode}).` : '' }
                    </Title>
                </Group>
                <Paper color='white' p={20} radius='6px' shadow='0'>
                    <Title fz='20px' fw={500} ff='Zilla Slab' c='var(--blue-shade)' ta='center'>
                        {text}
                    </Title>

                    { errorCode ? 
                    <Title fz='14px' fw={400} ff='Josefin Sans Variable' c='var(--blue-shade)' ta='center' mt={16}>
                    <b>Send to Tom: {errorCode}</b>
                    </Title> : null }
                </Paper>
            </Stack>
        </Paper>
    );
}

export default ErrorBox;

import { z } from 'zod';

import checkmark from '@/assets/metric-configs/mc-checkmark.png';
//import number from '@/assets/metric-configs/mc-numbers.png';
//import letterGrade from '@/assets/metric-configs/mc-letter-grades.png';
import emojiBattery from '@/assets/metric-configs/mc-emoji-battery.png';
import emojiSmiley from '@/assets/metric-configs/mc-emoji-smiley.png';
import emojiTriangle from '@/assets/metric-configs/mc-emoji-triangle.png';


export const MetricConfigurationSchema = z.object({
    _id: z.string().optional(),
    name: z.string(),
    metricType: z.string(),
    order: z.number(),
    includeInTotal: z.boolean(),
    locked: z.boolean(),
    active: z.boolean(),
});

export type MetricConfiguration = z.infer<typeof MetricConfigurationSchema>;


export interface IMetricConfiguration {
    name: string;
    metricType: string;
    //minValue: number;
    //maxValue: number;
    //interval: number;
    order: number;
    includeInTotal: Boolean;
    locked: Boolean;
    active: Boolean;
    //isNew?: Boolean;
    //isDefault?: Boolean;
    _id?: string;
}

export const metricConfigurationTypes = [
    {
        name: 'Checkmark',
        value: 'Checkmark',
        label: 'Checkmark',
        description: 'Simply check or uncheck your metric.',
        shortDescription: 'Check or uncheck your metric.',
        image: checkmark,
    },
    /*{
        name: 'Number',
        value: 'Number',
        label: 'Number',
        description: 'Input a number between 0 and 100 for your metric.',
        shortDescription: 'Any number between 0 and 100.',
        image: number,
    },
    {
        name: 'Letter-Grade',
        value: 'Letter-Grade',
        label: 'Letter Grades',
        description: 'Just like school, give yourself a grade from F to A+.',
        shortDescription: 'Just like school, give yourself a grade from F to A+.',
        image: letterGrade,
    },*/
    {
        name: 'Emoji-Battery',
        value: 'Emoji-Battery',
        label: 'Emojis (Batteries)',
        description: 'Select one of four distinct batteries to represent your metric.',
        shortDescription: 'Choose from empty, partial or fully charged batteries.',
        image: emojiBattery,
    },
    {
        name: 'Emoji-Smiley',
        value: 'Emoji-Smiley',
        label: 'Emojis (Faces)',
        description: 'Select one of five distinct faces to represent your metric.',
        shortDescription: 'Choose from five different smiley face emojis.',
        image: emojiSmiley,
    },
    {
        name: 'Emoji-Triangle',
        value: 'Emoji-Triangle',
        label: 'Emojis (Triangles)',
        description: 'Select one of five distinct triangles to represent your metric.',
        shortDescription: 'Choose from empty, partial or filled triangles.',
        image: emojiTriangle,
    },
];

export const getMetricTypeLogo = (metricTypeName: string) => {
    let result = metricConfigurationTypes.find((e) => e.name === metricTypeName);

    return result ? result.image : null;
};

export const getMetricShortDescription = (metricTypeName: string) => {
    let result = metricConfigurationTypes.find((e) => e.name === metricTypeName);

    return result ? result.shortDescription : null;
};

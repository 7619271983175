// Libraries
import React from 'react';

// Components
import { Container, Button, Title, Image, Stack, Divider } from '@mantine/core';
import Login from './Login';
import Signup from './Signup';

// Styles
import styles from './Splash.module.css';

// Images
import clouds from './clouds.png';
import home from './home.png';

export function Splash() {
    // Hooks
    const [showForm, toggleForm] = React.useState('');

    // Determines whether to render login or signup component
    const renderLoginOrSignup = () => {
        if (showForm === 'login') return <Login toggleForm={toggleForm} />;
        else if (showForm === 'signup') return <Signup toggleForm={toggleForm} />;

        return null;
    };

    // Render
    return (
        <Container size={'lg'} mt={{ base: '10px', lg: '40px' }}>
            <div className={styles.splashOrientation}>
                {showForm === '' ? (
                    <Stack
                        w={{ base: '100%', md: '55%', lg: '50%' }}
                        mt={0}
                        mb={{ base: 16, lg: 40 }}
                        mx={{ sm: '60px', md: '30px', lg: 0 }}
                    >
                        <Title
                            ff='Zilla Slab'
                            ta='left'
                            c={'var(--blue-shade)'}
                            fw={600}
                            fz={{ base: '32px', xs: '40px', sm: '44px', md: '48px', lg: '54px' }}
                            mb={{ base: 0, md: 8 }}
                        >
                            From dreaming to <br /> achieving, your way.
                        </Title>
                        <Title
                            ff='Zilla Slab'
                            ta='left'
                            c={'#827A6E'}
                            fw={500}
                            fz={{ base: '20px', xs: '24px', sm: '25px', md: '26px', lg: '28px' }}
                            lts='5%'
                            mb={{ base: 8, md: 16 }}
                        >
                            Custom metric tracking. <br />
                            Detailed insights and analytics.
                        </Title>
                        <Stack visibleFrom='md' w='100%' align='left' mt={24}>
                            <Button
                                h='50px'
                                bg='#001427'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                w='300px'
                                radius='xs'
                                onClick={() => toggleForm('login')}
                            >
                                Sign In
                            </Button>
                            <Divider
                                w='300px'
                                size='xs'
                                label='OR'
                                labelPosition='center'
                                color='#ABABAB'
                                my={8}
                                ff='Josefin Sans Variable'
                                fw={600}
                            />
                            <Button
                                h='50px'
                                bg='#AEA69A'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                w='300px'
                                radius='xs'
                                leftSection={<div className={styles.beta}>beta</div>}
                                onClick={() => toggleForm('signup')}
                            >
                                Become a Crow
                            </Button>
                        </Stack>
                    </Stack>
                ) : null}

                {showForm === '' ? (
                    <>
                        <Stack align='center' maw={{ base: '350px', lg: '50%' }} mx={'auto'} mb={8}>
                            <Image src={clouds} w={{ base: '300px', md: '500px', lg: '600px' }} />
                            <Image
                                src={home}
                                w={{
                                    base: '180px',
                                    xs: '200px',
                                    sm: '250px',
                                    md: '350px',
                                    lg: '400px',
                                }}
                                mt={{ base: -60, lg: -80 }}
                            />
                        </Stack>

                        <Stack hiddenFrom='md' w='100%' align='center' mt={24}>
                            <Button
                                h='50px'
                                bg='#001427'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                w='300px'
                                radius='sm'
                                onClick={() => toggleForm('login')}
                            >
                                Sign In
                            </Button>
                            <Divider
                                w='300px'
                                size='xs'
                                label='OR'
                                labelPosition='center'
                                color='#ABABAB'
                                my={0}
                                ff='Josefin Sans Variable'
                                fw={600}
                            />
                            <Button
                                h='50px'
                                bg='#AEA69A'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                w='300px'
                                radius='sm'
                                leftSection={<div className={styles.beta}>beta</div>}
                                onClick={() => toggleForm('signup')}
                            >
                                Become a Crow
                            </Button>
                        </Stack>
                    </>
                ) : (
                    renderLoginOrSignup()
                )}
            </div>
        </Container>
    );
}

export default Splash;

// Libraries
import { Paper, Stack, Title, Loader } from '@mantine/core';

interface isPendingProps {
    text: string;
}

export function IsPending({ text }: isPendingProps) {
    return (
        <Paper p='40px' radius='4px' color='rgba(0,0,0,0.1)' w='100%' h='100%' mt={20}>
            <Stack align='center'>
                <Loader size='36px' color='var(--blue-shade)' />
                <Title fz='20px' ff='Zilla Slab' c='var(--blue-shade)'>
                    {text}
                </Title>
            </Stack>
        </Paper>
    );
}

export default IsPending;

// Libraries
import axios from 'axios';

// Types
import { IServerError } from '@/types/errors.types';

// Utils
import { buildResponse } from '@/utils/requests.ts';

// Auth
import { updateTokenExpired } from '@/auth/auth.store';

export const pingApi = async () => {
    try {
        const { data } = await axios.get('/api');
        return data;
    } catch (error) {
        return buildResponse(false, null, 'Unknown error - please try again.');
    }
};


// Intercept any errors from the server
// If 401, set token as expired to show banner
// otherwise, return a nicely formatted error
axios.interceptors.response.use(
    (response) => {
      // Modify the response data here
      return response;
    },
    (error) => {
      // Handle response errors
      if(error.response?.status === 401) {
        updateTokenExpired();
      }

      const errorResponse: IServerError = {
        statusCode: error.response?.status || 500,
        message: error.response?.data?.message || 'Unknown error - please try again.',
        errorCode: error.response?.data?.data || 0,
        data: error.response?.data?.data || null,
      }

      return Promise.reject(errorResponse);
    }
  );


// Router
import { Routes, Route, Navigate } from 'react-router-dom';

// Auth
import { PrivateRoutes } from '@/auth/PrivateRoutes';

// Pages
import Splash from '@/pages/splash/Splash';
import Dashboard from '@/pages/dashboard/Dashboard';
import Onboarding from '@/pages/onboarding/Onboarding';
import Trends from '@/pages/trends/Trends';
import Settings from '@/pages/settings/Settings';

// Styles
import '@mantine/core/styles.css';

// Components
import RootLayout from './RootLayout';

export default function App() {
    return (
        <Routes>
            <Route path='*' element={<RootLayout />}>
                <Route path='' element={<Navigate to='/login' replace />} />
                <Route path='login' element={<Splash />} />
                <Route element={<PrivateRoutes />}>
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='trends' element={<Trends />} />
                    <Route path='settings' element={<Settings />} />
                    <Route path='onboarding' element={<Onboarding />} />
                </Route>
                <Route path='*' element={<Navigate to='/login' replace />} />
            </Route>
        </Routes>
    );
}

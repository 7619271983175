import axios from 'axios';

// Utils
import { buildHeaders } from '@/utils/requests.ts';
import { IDay } from '@/types/day.types';

export const getUserDay = async (date: string) => {

    const { data } = await axios.get(`/api/v1/days/${date}`, buildHeaders());
    return data.data;

};

export const updateUserDay = async (day: IDay) => {

    const { data } = await axios.put(`/api/v1/days/${day._id}`, { day: day }, buildHeaders());
    return data.data;
    
}

export const getUserMonth = async (shortDate: string) => {

    const { data } = await axios.get(`/api/v1/months/${shortDate}`, buildHeaders());
    return data.data;
}
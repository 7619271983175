// Libraries
import { useState } from 'react';
import { Text, Paper, Menu, Group, Loader } from '@mantine/core';

// Api
import { pingApi } from '@/api/general.api';

// App Details
import appDetails from '../../../package.json';

// Styles
import styles from './Status.module.css';

export function Status() {
    // Hooks
    const [serverVersion, setServerVersion] = useState('?');
    const [isLoading, setLoading] = useState(true);
    const [currentStatus, updateStatus] = useState(true);

    const pingServer = async () => {
        setLoading(true);

        const response = await pingApi();

        if (response.success) {
            setLoading(false);
            setServerVersion(response.data);
            updateStatus(true);
        } else {
            setLoading(false);
            setServerVersion('?');
            updateStatus(false);
        }
    };

    return (
        <Menu shadow='sm' offset={8} position='bottom-end' radius='md' onOpen={pingServer}>
            <Menu.Target>
                <Paper className={styles.statusBox} bg='#001427'>
                    <div
                        className={styles.circle}
                        style={{ background: currentStatus ? '#7FBBBD' : '#BE5C61' }}
                    ></div>
                    <Text c='white' ff='Josefin Sans Variable' fz={'15px'} fw='600' pt={3}>
                        v{appDetails.version}
                    </Text>
                </Paper>
            </Menu.Target>
            <Menu.Dropdown p={12}>
                <Menu.Label
                    w='100%'
                    ta='center'
                    ff='Josefin Sans Variable'
                    fz='11px'
                    fw={600}
                    c='#AEA69A'
                >
                    VERSIONS
                </Menu.Label>
                <Menu.Item py={6} px={12}>
                    <Group justify='space-between'>
                        <Text ff='Zilla Slab' fw={500}>
                            app
                        </Text>
                        <Text ff='Josefin Sans Variable' pt={4}>
                            {appDetails.version}
                        </Text>
                    </Group>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item py={6} px={12}>
                    <Group justify='space-between'>
                        <Text ff='Zilla Slab' fw={500}>
                            api
                        </Text>
                        {isLoading ? (
                            <Loader type='dots' color='#5D7D7E' />
                        ) : (
                            <Text ff='Josefin Sans Variable' pt={4}>
                                {serverVersion}
                            </Text>
                        )}
                    </Group>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export default Status;

// Libraries
import { useState } from 'react';
import { FiMail, FiShield, FiAlertOctagon, FiUser } from 'react-icons/fi';
import { useForm, isEmail, hasLength } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

// Auth
import { useActions } from '@/auth/auth.store.ts';

// Api
import { signup } from '@/api/auth.api.ts';

// Components
import {
    Image,
    Stack,
    Text,
    Input,
    PasswordInput,
    Group,
    Button,
    Card,
    Title,
    Flex,
    Paper,
    Divider,
} from '@mantine/core';

// Images
import google from './google.png';
import apple from './apple.png';
import x from './x.png';
import { useMutation } from '@tanstack/react-query';

// Interface
import { ISignupParams } from '@/types/auth.types';

interface Props {
    toggleForm: (page: string) => void;
}


// Signup Component
export function Signup({ toggleForm }: Props) {
    
    // Hooks
    const [message, setMessage] = useState('');
    const { setToken } = useActions();
    const navigate = useNavigate();

    // React Query Mutation
    const signupMutation = useMutation({
        mutationFn: signup,
        onSuccess: (data) => {
            setToken(data);
            console.log('Registration Successful!');
            navigate('/dashboard');
        },
        onError: (error) => {
            setMessage('Oops! ' + error.message);
        }
    });

    // Form Definition
    const form = useForm<ISignupParams>({
        mode: 'controlled',
        validateInputOnChange: true,
        initialValues: {
            email: '',
            password: '',
            name: '',
        },
        validate: {
            name: hasLength({ min: 2, max: 28 }, 'Name must be between 2-28 letters.'),
            email: isEmail('Invalid email address.'),
            password: hasLength({ min: 8, max: 32 }, 'Password must be between 8-32 characters.'),
        },
    });

    
    // Call mutation on form submit
    const onSignup = async (values: typeof form.values) => {
        signupMutation.mutate(values);
    };

    // Render
    return (
        <Flex w='100%' justify='center' align='flex-start' mt={20}>
            <form onSubmit={form.onSubmit(onSignup)}>
                <Card
                    bg={'var(--card-bg)'}
                    radius='md'
                    shadow='none'
                    maw='400px'
                    w='100%'
                    p={{ base: 24, sm: 36 }}
                >
                    <Title size='32px' ff='Zilla Slab' fw={600} mb={5}>
                        Fly higher.
                    </Title>
                    <Title c={'var(--medium-tan-shade)'} size='18px' ff='Zilla Slab' fw={500}>
                        Ready to become your best self?
                    </Title>
                    <Stack
                        align='center'
                        justify='center'
                        mb={{ base: 20, md: 30 }}
                        w='100%'
                        mt={20}
                    >
                        <Group wrap='nowrap'>
                            <Input.Wrapper error={form.errors?.name} w='100%' ff='Zilla Slab'>
                                <Input
                                    w='100%'
                                    miw='280px'
                                    ff='Zilla Slab'
                                    fz='16px'
                                    size='md'
                                    placeholder='Your name'
                                    leftSection={<FiUser />}
                                    key={form.key('name')}
                                    {...form.getInputProps('name')}
                                />
                            </Input.Wrapper>
                        </Group>
                        <Input.Wrapper error={form.errors?.email} w='100%' ff='Zilla Slab'>
                            <Input
                                w='100%'
                                miw='280px'
                                ff='Zilla Slab'
                                size='md'
                                placeholder='Email'
                                leftSection={<FiMail />}
                                key={form.key('email')}
                                {...form.getInputProps('email')}
                            />
                        </Input.Wrapper>
                        <PasswordInput
                            w='100%'
                            miw='280px'
                            ff='Zilla Slab'
                            size='md'
                            key={form.key('password')}
                            placeholder='Password'
                            leftSection={<FiShield />}
                            {...form.getInputProps('password')}
                            error={form.errors.password?.toString()}
                        />
                        {message !== '' ? (
                            <Paper w='100%' px={10} py={8} bg={'var(--red)'} c='white'>
                                <Group justify='flex-start' gap={10}>
                                    <FiAlertOctagon size={22} color='white' />
                                    <Text size='16px' fw={600} ff='Zilla Slab'>
                                        {message}
                                    </Text>
                                </Group>
                            </Paper>
                        ) : null}
                        <Group w='100%' mt={8}>
                            <Button
                                bg={'var(--medium-tan-tint)'}
                                onClick={() => toggleForm('')}
                                h='46px'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                radius='xs'
                            >
                                back
                            </Button>
                            <Button
                                type='submit'
                                bg={'var(--blue-shade-max)'}
                                h='46px'
                                ff='Zilla Slab'
                                fw={600}
                                fz='22px'
                                radius='sm'
                                style={{ flexGrow: 1 }}
                                loading={signupMutation.isPending}
                            >
                                { message === '' ? 'Register' : 'Try Again' }
                            </Button>
                        </Group>
                        <Divider
                            w='100%'
                            size='xs'
                            label='COMING SOON'
                            labelPosition='center'
                            color={'var(--medium-tan-shade)'}
                            my={8}
                            ff='Josefin Sans Variable'
                            fw={600}
                        />
                        <Group grow wrap='nowrap' mb={40} p={0} gap='sm' w='100%'>
                            <Button
                                bg={'var(--blue-shade-max)'}
                                h='40px'
                                ff='Zilla Slab'
                                fw={600}
                                fz='20px'
                                radius='sm'
                                fullWidth
                            >
                                <Image src={google} h='24px' />
                            </Button>
                            <Button
                                bg={'var(--blue-shade-max)'}
                                h='40px'
                                ff='Zilla Slab'
                                fw={600}
                                fz='20px'
                                radius='sm'
                            >
                                <Image src={apple} />
                            </Button>
                            <Button
                                bg={'var(--blue-shade-max)'}
                                h='40px'
                                ff='Zilla Slab'
                                fw={600}
                                fz='20px'
                                radius='sm'
                            >
                                <Image src={x} />
                            </Button>
                        </Group>
                        <Text c={'var(--medium-tan-shade)'} size='18px' ff='Zilla Slab' fw={500}>
                            Forgot your password?
                        </Text>
                    </Stack>
                </Card>
            </form>
        </Flex>
    );
}

export default Signup;

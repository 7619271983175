// Libraries
import { Container, Title, Card, Group } from '@mantine/core';
import { LineChart, Line, ResponsiveContainer, Dot } from 'recharts';

// Components
//import ComingSoon from '@/components/comingsoon/ComingSoon.tsx';
import { FiChevronDown } from 'react-icons/fi';

export function Trends() {
    const data = [
        {
            name: 'Page A',
            uv: 1200,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 650,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 1650,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <Container size={'lg'} mt={{ base: '10px', md: '20px' }}>
            <Title
                c={'var(--blue-shade)'}
                fz={{ base: '28px', xs: '32px', sm: '36px', md: '40px', lg: '48px' }}
                ff='Zilla Slab'
                fw={600}
            >
                Trends & Analytics
            </Title>
            <Title
                c={'#827A6E'}
                fz={{ base: '18px', xs: '21px', sm: '24px', md: '26px', lg: '30px' }}
                ff='Zilla Slab'
                fw={400}
                mb={20}
            >
                Learn more about yourself.
            </Title>
            <Card w='100%' h='220px' bg='#1B3043' radius='18px' p='lg' mb={25}>
                <Group justify='space-between' w='100%' mb='10px'>
                    <Group gap={4}>
                        <Title c={'white'} fz='18px' ff='Zilla Slab' fw={600}>
                            Some Metric
                        </Title>
                        <FiChevronDown color='#6e8ca8' size={20} />
                    </Group>
                    <Group gap={4}>
                        <Title c={'white'} fz='16px' ff='Zilla Slab' fw={600}>
                            this week
                        </Title>
                        <FiChevronDown color='#6e8ca8' size={20} />
                    </Group>
                </Group>
                <ResponsiveContainer width='100%' height='100%'>
                    <LineChart width={300} height={100} data={data}>
                        <Line
                            type='monotone'
                            dataKey='pv'
                            stroke='#F4A15B'
                            strokeWidth={2}
                            dot={<Dot stroke='#F4A15B' fill='#1B3043' r={3} />}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Card>

            <Card w='100%' h='220px' bg='#1B3043' radius='18px' p='lg' mb={25}>
                <Group justify='space-between' w='100%' mb='20px'>
                    <Group gap={4}>
                        <Title c={'white'} fz='18px' ff='Zilla Slab' fw={600}>
                            Correlated
                        </Title>
                        <FiChevronDown color='#6e8ca8' size={20} />
                    </Group>
                    <Group gap={4}>
                        <Title c={'white'} fz='16px' ff='Zilla Slab' fw={600}>
                            this month
                        </Title>
                        <FiChevronDown color='#6e8ca8' size={20} />
                    </Group>
                </Group>
                <ResponsiveContainer width='100%' height='100%'>
                    <LineChart width={300} height={100} data={data}>
                        <Line
                            type='monotone'
                            dataKey='uv'
                            stroke='#8589C6'
                            strokeWidth={2}
                            dot={<Dot stroke='#8589C6' fill='#1B3043' r={3} />}
                        />
                        <Line
                            type='monotone'
                            dataKey='amt'
                            stroke='#67BEB3'
                            strokeWidth={2}
                            dot={<Dot stroke='#67BEB3' fill='#1B3043' r={3} />}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        </Container>
    );
}

export default Trends;

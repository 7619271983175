// Libraries
import { useState } from 'react';
import { Container, Title, Stack, Group, Space } from '@mantine/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs'
//import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/zh-cn' // import locale

// Auth
import { useUser } from '@/auth/auth.store';

// Components
import { IsPending } from '@/components/isPending/IsPending';
import { ErrorBox } from '@/components/errorbox/ErrorBox';
import DayForm from './DayForm';
import NewCalendar from '@/components/calendar/NewCalendar';

// Api
import { getUserDay, updateUserDay } from '@/api/day.api';
import { getUserProfile } from '@/api/userProfile.api';

// Types
import { IDay } from '@/types/day.types';
import { UserProfile } from '@/types/auth.types';
import { IServerError } from '@/types/errors.types';

export function Dashboard() {

    const today = dayjs(); //.format('YYYY-MM-DD');
    const [activeDate, setActiveDate] = useState(today);
    
    // Hooks
    const user = useUser();
    const userId = user ? user._id : '';

    const { isPending: isUserProfilePending, isError: isUserProfileError, data: userProfile, error: userProfileError } = useQuery<UserProfile, IServerError>({
        queryKey: ['user-profile', userId],
        queryFn: () => getUserProfile(userId),
        retry: 1,
    });
    const {isPending: isDayPending, isError: isDayError, data: dayData, error: dayError } = useQuery<IDay, IServerError>({ 
        queryKey: ['user-day', activeDate.format('YYYY-MM-DD')], 
        queryFn: () => getUserDay(activeDate.format('YYYY-MM-DD')),
        retry: 1,
    });

    const queryClient = useQueryClient();

    // Functions
    const updateMutation = useMutation({
        mutationFn: updateUserDay,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user-day', activeDate.format('YYYY-MM-DD')] });
            queryClient.invalidateQueries({ queryKey: ['user-month', activeDate.format('YYYY-MM')] });
        }
    });

    const onSubmit = (updatedDay: IDay) => { 

        updateMutation.mutate(updatedDay);
    }

    const renderGreeting = (hours: number) => {
        if (hours >= 18) return `Good Evening, ${user?.name}.`;
        else if (hours >= 12) return `Good Afternoon, ${user?.name}.`;

        return `Good Morning, ${user?.name}.`;
    };

    // Render
    if (isDayPending || isUserProfilePending) {
        return <IsPending text='Loading your day..' />;
    }
    else if(isUserProfileError) {
        return <ErrorBox text={userProfileError.message} serverError={userProfileError}/>;
    }
    else if (isDayError) {    
        return <ErrorBox text={dayError.message} serverError={dayError}/>;
    }
    else if (dayData === null || userProfile === null) {
        return <ErrorBox text={"Hmm not sure what's happened. Please refresh."} />;
    }
    else if(dayData && userProfile) {

        return (
            <Container size={'lg'} mt={{ base: '0px', md: '20px' }} p='xs' id={activeDate.format('YYYY-MM-DD')}>
                <Group align='flex-start' justify='space-around'>
                    <Stack gap={0} mb={20} align='center'>
                        <Title
                            w='100%'
                            c={'var(--blue-shade)'}
                            fz={{ base: '28px', xs: '32px', sm: '36px', md: '40px', lg: '48px' }}
                            ff='Zilla Slab'
                            fw={600}
                            ta='center'
                            mb='10px'
                        >
                            {renderGreeting(today.hour())}
                        </Title>
                        
                        <NewCalendar activeDate={activeDate} selectActiveDate={setActiveDate} />
                       
                    </Stack>
                    
                </Group>
                <DayForm dayData={dayData} userProfile={userProfile} updating={updateMutation.isPending} onSaveDay={onSubmit}/>
                <Space h='xl'/>
            </Container>
        );
    }
}

export default Dashboard;

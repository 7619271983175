import axios from 'axios';

// Types
import { UserProfile } from '@/types/auth.types';

// Utils
import { buildHeaders } from '@/utils/requests.ts';

export const getUserProfile = async (userId: string) => {
    const { data } = await axios.get(`/api/v1/users/profile/${userId}`, buildHeaders());
    return data.data;
};

export const updateUserProfile = async (userProfile: UserProfile) => {

    const { data } = await axios.post(`/api/v1/users/profile/${userProfile._id}`, { user: userProfile }, buildHeaders());
    return data.data;
    
}
// Libraries
import { Outlet, useLocation } from 'react-router-dom';
import { Suspense } from 'react';

// Components
import { AppShell, Group, Container, Loader, Text } from '@mantine/core';
import NavMenu from '@/components/navmenu/NavMenu';
import Navbar from '@/components/navbar/Navbar';

// Styles
import styles from './App.module.css';
import { useTokenExpired, useActions } from '@/auth/auth.store';
import { FiAlertCircle } from 'react-icons/fi';

export default function RootLayout() {

    // Hooks
    const { pathname } = useLocation();

    // Auth Hooks
    const tokenExpired = useTokenExpired();
    const { clearToken } = useActions();


    // Render the expiry banner
    const expiryBanner = () => {
        return (
            <div className={styles.expiryBanner} onClick={clearToken}>
                <Group>
                    <FiAlertCircle size={24} color='white'/>
                    <Text size='18px' fw={600} c='white'>Your Session has expired.  Click here to Sign In again.</Text>
                </Group>
            </div>
        );
    }

    const headerSize = tokenExpired ? { base: 110, md: 120, lg: 130 } : { base: 60, md: 70, lg: 80 };

    // Render
    return (
        <AppShell
            header={{ height: headerSize }}
            padding={{ base: 'sm', sm: 'md', md: 'lg', lg: 0, xl: 'lg' }}
            className={styles.appShell}
        >
            
            <AppShell.Header withBorder={false} className={styles.navbar}>
                { tokenExpired ? expiryBanner() : null }
                <Navbar />
            </AppShell.Header>

            <AppShell.Main style={{ flexGrow: 1, overflow: 'hidden' }}>
                <Suspense fallback={<Loader color='#423F61' />}>
                    <Outlet />
                </Suspense>
            </AppShell.Main>

            {pathname !== '/login' ? (
                <AppShell.Footer
                    hiddenFrom='lg'
                    h='80px'
                    className={styles.footer}
                    withBorder={false}
                >
                    <Container size='lg' h='100%'>
                        <Group justify='center' align='center' h='100%'>
                            <NavMenu />
                        </Group>
                    </Container>
                </AppShell.Footer>
            ) : null}
        </AppShell>
    );
}


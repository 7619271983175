// Components
import { Container, Title } from '@mantine/core';

// Images
export function Onboarding() {
    // Hooks

    // Render
    return (
        <Container size={'lg'} mt={{ base: '10px', lg: '40px' }}>
            <Title c={'var(--blue-shade)'} size='24px' ff='Zilla Slab' fw={600}>
                So how does this work?{' '}
            </Title>
            <Title c={'#827A6E'} size='18px' ff='Zilla Slab' fw={400}>
                Each day, you log a few easy metrics. <br />
                Just like this...
            </Title>
        </Container>
    );
}

export default Onboarding;

// Libraries
import { Image, Text, Container, Menu } from '@mantine/core';
import { FiActivity, FiSettings, FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

// Auth
import { useActions, useUser } from '@/auth/auth.store';

// Components
import Status from '../status/Status';

// Styles
import styles from './Navbar.module.css';

// Images
import nestIconBlue from '@/assets/nest-icon-blue.svg';
import crowBird from '@/assets/crow-bird.png';
import crowText from '@/assets/crow-text-logo.png';
import userIconGroovy from '@/assets/user-icon-groovy.png';

export function Navbar() {

    // Hooks
    const user = useUser();
    const { clearToken } = useActions();

    const navigate = useNavigate();

    const menuOptions = [
        {
            name: 'Dashboard',
            pathname: '/dashboard',
            key: 'dashboard',
            icon: <Image src={nestIconBlue} h='18px' />,
        },
        {
            name: 'Trends',
            pathname: '/trends',
            key: 'trends',
            icon: <FiActivity size={20} color='var(--blue-shade)' style={{ margin: '0 8px' }} />,
        },
        {
            name: 'Settings',
            pathname: '/settings',
            key: 'settings',
            icon: <FiSettings size={20} color='var(--blue-shade)' style={{ margin: '0 8px' }} />,
        },
    ];

    return (
        <Container size='lg' h='100%'>
            <div className={styles.navInterior}>
                <div className={styles.crowLogo} onClick={() => navigate('/login')}>
                    <Image src={crowBird} h='34px' />
                    <Image src={crowText} h='28px' />
                </div>
                <div className={styles.navbarRight}>
                    <Status />

                    {user ? (
                        <Menu
                            shadow='sm'
                            width={170}
                            position='bottom-end'
                            offset={10}
                            radius={'md'}
                        >
                            <Menu.Target>
                                <Image
                                    src={userIconGroovy}
                                    height='40px'
                                    className={styles.userProfileIcon}
                                />
                            </Menu.Target>
                            <Menu.Dropdown p={5}>
                                {menuOptions.map((option) => (
                                    <Menu.Item
                                        key={option.key}
                                        onClick={() => navigate(option.pathname)}
                                        leftSection={option.icon}
                                    >
                                        <Text ff='Zilla Slab' fz={18} fw={500}>
                                            {option.name}
                                        </Text>
                                    </Menu.Item>
                                ))}
                                <Menu.Divider />
                                <Menu.Item
                                    leftSection={
                                        <FiLogOut
                                            color='#9D1A22'
                                            size={20}
                                            style={{ margin: '0 8px' }}
                                        />
                                    }
                                >
                                    <Text ff='Zilla Slab' fz={18} fw={500} onClick={clearToken}>
                                        Logout
                                    </Text>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    ) : null}
                </div>
            </div>
        </Container>
    );
}

export default Navbar;

// Libraries
import {
    Image,
    Title,
    Stack,
    Group,
    Text,
    SelectProps,
    Card,
    Button,
    Divider,
    TextInput,
    Select,
    Switch,
    Paper,
} from '@mantine/core';
import { useForm, hasLength } from '@mantine/form';

// Styles
import styles from './MetricConfiguration.module.css';

// Types
import {
    getMetricShortDescription,
    getMetricTypeLogo,
    IMetricConfiguration,
    metricConfigurationTypes,
} from '@/types/metrics';
import { FiAlertOctagon } from 'react-icons/fi';
import { useEffect } from 'react';

// Images
interface MetricConfigurationEditorProps {
    editingType: string;
    metricConfiguration: IMetricConfiguration;
    isSaving: boolean;
    onCancel: () => void;
    onCreate: (newMetricConfiguration: IMetricConfiguration) => void;
    onUpdate: (updatedMetricConfiguration: IMetricConfiguration) => void;
    onDelete: (id: string) => void;
}

export function MetricConfigurationEditor({editingType, metricConfiguration, isSaving, onCancel, onCreate, onUpdate, onDelete}: MetricConfigurationEditorProps) {
    
    // Hooks
    const message = '';

    // Form management
    const form = useForm<IMetricConfiguration>({
        mode: 'controlled',
        validateInputOnChange: true,
        initialValues: {
            name: metricConfiguration.name,
            metricType: metricConfiguration.metricType,
            order: metricConfiguration.order,
            includeInTotal: metricConfiguration.includeInTotal,
            locked: metricConfiguration.locked,
            active: metricConfiguration.active,
        },
        validate: {
            name: hasLength({ min: 1, max: 28 }, 'Name must be between 1-28 characters.'),
        },
        transformValues: (values) => ({
            ...values,
            includeInTotal: Boolean(values.includeInTotal),
        }),
    });

    useEffect(() => {

        if(metricConfiguration._id)
            form.setValues({
                name: metricConfiguration.name,
                metricType: metricConfiguration.metricType,
                order: metricConfiguration.order,
                includeInTotal: metricConfiguration.includeInTotal,
                locked: metricConfiguration.locked,
                active: metricConfiguration.active,
            });

    }, [metricConfiguration]);

    // Submit a new or updated metric configuration
    const onSubmit = async (values: typeof form.values) => {

        if(editingType === 'create') {
            const newMetricConfiguration: IMetricConfiguration = {
                name: values.name,
                metricType: values.metricType,
                order: 0,
                includeInTotal: values.includeInTotal,
                locked: false,
                active: true,
            };

            onCreate(newMetricConfiguration);

        }
        else if(editingType === 'edit') {
            const updatedMetricConfiguration: IMetricConfiguration = {
                name: values.name,
                metricType: values.metricType,
                order: values.order,
                includeInTotal: values.includeInTotal,
                locked: values.locked,
                active: values.active,
            };
            onUpdate(updatedMetricConfiguration);
        }
    };

    // Delete a single Metric Configuration
    const handleDelete = async () => {
        if(metricConfiguration._id)
            onDelete(metricConfiguration._id);
    };

    const renderSelectDropdown: SelectProps['renderOption'] = ({ option }) => (
        <Group flex='1' gap='xs' p={4}>
            <Image src={getMetricTypeLogo(option?.value)} h='36px' mr='10px' />
            <Stack gap={0} miw='50%' w='70%' style={{ flexGrow: 1 }}>
                <Title fz='18px' ff='Zilla Slab'>
                    {option.label}
                </Title>
                <Text ff='Zilla Slab' fw={400} fz='15px'>
                    {getMetricShortDescription(option?.value)}
                </Text>
            </Stack>
        </Group>
    );

    return (
        <Card
            shadow={editingType === 'create' ? 'none' : 'xs'}
            padding={editingType === 'create' ? 'sm' : 'lg'}
            radius='md'
            w={{ base: '100%' }}
            bg='#FAF8F5'
            h='fit-content'
        >
            {/*{editingType === 'nothing' ? (
                <Flex align={'center'} justify={'center'} p={20}>
                    <Stack align='center' justify='space-around' h='100%'>
                        <Button
                            onClick={onCreate}
                            bg='#001427'
                            h='40px'
                            ff='Zilla Slab'
                            fw={600}
                            fz='18px'
                            leftSection={<FiPlusCircle size={20} color='var(--teal-tint)' />}
                            radius='sm'
                        >
                            Add a New Metric
                        </Button>
                        <Divider label='OR' labelPosition='center' w='80%' color='#ABABAB' my={8} />
                        <Title ff='Zilla Slab' fz='24px' ta='center'>
                            Select an existing metric to edit.
                        </Title>
                    </Stack>
                </Flex>
            ) : (*/}
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack gap={15}>
                        <Stack gap={0} mb={5}>
                            <Title
                                c={'var(--blue-shade)'}
                                fz={{ base: '24px', md: '28px' }}
                                ff='Zilla Slab'
                                fw={600}
                            >
                                {editingType === 'create'
                                    ? 'Create your new metric.'
                                    : 'Editing Metric: ' + metricConfiguration.name}
                            </Title>
                            <Title
                                c={'#827a6e'}
                                fz={{ base: '16px', md: '18px' }}
                                ff='Zilla Slab'
                                fw={400}
                            >
                                Don't worry, you can always edit this later.
                            </Title>
                        </Stack>
                        <Group preventGrowOverflow mb={10}>
                            <TextInput
                                size='md'
                                label='Metric Name'
                                placeholder='Choose a simple name'
                                key={form.key('name')}
                                {...form.getInputProps('name')}
                                style={{ flexGrow: 1 }}
                                w={{ base: '100%', md: '45%' }}
                            />
                            <Select
                                size='md'
                                label='Metric Type'
                                placeholder='How would you like to measure?'
                                data={metricConfigurationTypes}
                                renderOption={renderSelectDropdown}
                                key={form.key('metricType')}
                                {...form.getInputProps('metricType')}
                                style={{ flexGrow: 1 }}
                                w={{ base: '100%', md: '45%' }}
                                leftSection={
                                    <Image
                                        src={getMetricTypeLogo(form.values.metricType)}
                                        h='26px'
                                    />
                                }
                            />
                        </Group>
                        <Group justify='space-between'>
                            <Stack gap={0}>
                                <Text className='form-label'>Include in Score?</Text>
                                <Switch
                                    className={styles.switch}
                                    size='lg'
                                    onLabel='Yes'
                                    offLabel='No'
                                    color='var(--teal)'
                                    key={form.key('includeInTotal')}
                                    checked={form.values.includeInTotal}
                                    {...form.getInputProps('includeInTotal')}
                                />
                            </Stack>
                            <Stack gap={0}>
                                <Text className='form-label'>Example</Text>
                                <Button
                                    variant='filled'
                                    w='126px'
                                    size='sm'
                                    radius='sm'
                                    ff='Zilla Slab'
                                    fz='18px'
                                    color='var(--purple)'
                                >
                                    show
                                </Button>
                            </Stack>
                        </Group>
                        {message !== '' ? (
                            <Paper w='100%' px={10} py={8} bg='#A24348' mt={10} c='white'>
                                <Group justify='flex-start' gap={10}>
                                    <FiAlertOctagon size={22} color='white' />
                                    <Text size='16px' fw={600} ff='Zilla Slab'>
                                        {message}
                                    </Text>
                                </Group>
                            </Paper>
                        ) : null}

                        <Stack>
                            <Divider my={15} />
                            <Group>
                                <Button
                                    onClick={onCancel}
                                    bg='#AEA69A'
                                    h='36px'
                                    ff='Zilla Slab'
                                    fw={600}
                                    fz='18px'
                                    radius='sm'
                                >
                                    cancel
                                </Button>
                                {metricConfiguration.locked ? (
                                    <Button
                                        bg='#A24348'
                                        h='42px'
                                        ff='Zilla Slab'
                                        fw={600}
                                        fz='18px'
                                        radius='sm'
                                        style={{ flexGrow: 1 }}
                                    >
                                        Can't Edit Locked Metrics!
                                    </Button>
                                ) : (
                                    <Button
                                        bg='#001427'
                                        h='36px'
                                        ff='Zilla Slab'
                                        fw={600}
                                        fz='18px'
                                        radius='sm'
                                        style={{ flexGrow: 1 }}
                                        type='submit'
                                        loading={isSaving}
                                    >
                                        Save Changes
                                    </Button>
                                )}
                            </Group>
                            {editingType === 'create' || metricConfiguration.locked ? null : (
                                <Button
                                    onClick={handleDelete}
                                    variant='subtle'
                                    ff='Zilla Slab'
                                    color='var(--red-shade)'
                                    fz='18px'
                                >
                                    Delete this metric?
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </form>
            {/*})}*/}
        </Card>
    );
}

export default MetricConfigurationEditor;

// Libraries
import { useEffect } from 'react';
import { Stack, RingProgress, Button, Textarea, NumberInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FiCheckCircle } from 'react-icons/fi';

// Components
import Checkmark from '@/components/metricSelectors/Checkmark';
import PopoverEmoji from '@/components/metricSelectors/PopoverEmoji';

// Styles
import styles from './Dashboard.module.css';

// Types
import { IDay, IMetric } from '@/types/day.types';
import { IMetricConfiguration } from '@/types/metrics';
import { UserProfile } from '@/types/auth.types';

// Interface
interface DayFormProps {
    dayData: IDay;
    userProfile: UserProfile;
    updating: boolean;
    onSaveDay: (values: any) => void;
}

export function DayForm({ dayData, userProfile, updating, onSaveDay }: DayFormProps) {
    
    // MOve somewhere else as util or helper
    const determineActiveMetrics = (metricConfigurations: IMetricConfiguration[], metrics: IMetric[]) => {

        metricConfigurations.sort((a, b) => a.order - b.order);

        let activeMetrics: IMetric[] = [];
        //let activeMetrics: {metric: IMetric, metricConfiguration: IMetricConfiguration}[] = [];

        metricConfigurations.forEach((metricConfig) => {

            let metricMatch = metrics.find(
                (metric) => metric.metricConfigurationId === metricConfig._id,
            );

            // Tom TODO
            // make a hybrid object { metric: IMetric, metricConfiguration: IMetricConfiguration } to avoid the matching in render

            // No match, but the metricConfig exists and is active
            // Create a new metric
            if(metricMatch === undefined && metricConfig.active && metricConfig._id) {
                const newDailyMetric: IMetric = {
                    metricConfigurationId: metricConfig._id,
                    value: 0,
                    completed: false,
                }
                activeMetrics.push(newDailyMetric);
                //activeMetrics.push({metric: newDailyMetric, metricConfiguration: metricConfig});
            }
            // Match exists, and metric config is active
            // Also create metric
            else if(metricMatch !== undefined && metricConfig.active) {
                activeMetrics.push(metricMatch);
                //activeMetrics.push({metric: metricMatch, metricConfiguration: metricConfig});
            }

        });

        return activeMetrics;

    }
    
    // Form
    const dayForm = useForm<IDay>({   
        mode: 'controlled',
        initialValues: {
            _id: dayData._id,
            totalScore: dayData.totalScore,
            journal: dayData.journal,
            metrics: determineActiveMetrics(userProfile.metricConfigurations, dayData.metrics),
            date: dayData.date,
        }
    });

    // Use Effects

    // Update the total score every time a metric changes
    useEffect(() => {

        dayForm.setValues({
            totalScore: recalculateScore(dayForm.values.metrics),
        });

    }, [dayForm.values.metrics]);


    // Re-update the dayForm values if the dayData changes
    useEffect(() => {

        if(dayData._id !== dayForm.values._id) {
            dayForm.setValues({
                _id: dayData._id,
                totalScore: dayData.totalScore,
                journal: dayData.journal,
                metrics: determineActiveMetrics(userProfile.metricConfigurations, dayData.metrics),
                date: dayData.date,
            });
        }

    }, [dayData]);


    // Functions

    

    // Recalculate the total score
    const recalculateScore = (metrics:IMetric[]) => {

        let totalScore = 0;
        metrics.forEach((metric) => {
            totalScore = totalScore + metric.value;
        });

        const average = Math.floor(totalScore / metrics.length);

        if(average > 100) 
            return 100;

        return average;

    }

    // Convert the form values into IDay and return to Dashboard for saving
    const convertAndSubmit = async (values: typeof dayForm.values) => {

        const updatedDay: IDay = {
            _id: values._id,
            date: values.date,
            journal: values.journal,
            totalScore: values.totalScore,
            metrics: values.metrics,
        };
    
        onSaveDay(updatedDay);
    }


    // Update one specific metric
    const saveUpdatedMetric = (updatedMetric: IMetric, index: number) => {
            
        dayForm.setFieldValue('metrics.'+index, updatedMetric);
    }


    // Render the specific type of metric tool, based on the metric configuration
    const buildMetricTool = (metric: IMetric, metricConfiguration: IMetricConfiguration, index: number) => {

        if(['Emoji-Smiley', 'Emoji-Triangle', 'Emoji-Battery'].includes(metricConfiguration.metricType)) {
            return (
                <PopoverEmoji
                    metric={metric} 
                    metricConfiguration={metricConfiguration} 
                    onUpdate={saveUpdatedMetric}
                />
            )
        }
        else if(metricConfiguration.metricType === 'Checkmark') {
            return (
                <Checkmark
                    metric={metric} 
                    metricConfiguration={metricConfiguration} 
                    onUpdate={saveUpdatedMetric}
                />
            )
        }
        else {
            return (
                <div className={styles.stat} key={metric._id} >
                    <div className={styles.statName}>{metricConfiguration.name}</div>
                    <NumberInput
                        variant='unstyled'
                        size='xl'
                        className={styles.statValue}
                        key={dayForm.key('metrics.'+index+'.value')}
                        {...dayForm.getInputProps('metrics.'+index+'.value')}
                    />
                </div>
            )
        }

    }

    // Render Functions
    const requiredSpacers = 4 - ((dayForm.values.metrics.length + 1) % 4) + 4;
    const totalScoreColors = ['#596B7B', '#F85A5A', '#F4A15B', '#7FBBBD'];
    //'#F47850', '#FDBF16',

     const calculateScoreColor = (score: number) => {

        if(score >= 80) return totalScoreColors[3];
        else if (score >= 50) return totalScoreColors[2];
        else if (score >= 25) return totalScoreColors[1];
        else return totalScoreColors[0];
     }

    return (
        
        <form onSubmit={dayForm.onSubmit(convertAndSubmit)} className={styles.formShell}>
            <Stack w={{ base: '100%', md: '40%' }}>
                <div className={styles.journalShell}>
                <Textarea
                    key={dayForm.key('journal')}
                    {...dayForm.getInputProps('journal')}
                    className={styles.textArea}
                    autosize
                    minRows={3}
                    maxRows={4}
                    size='lg'
                    variant='unstyled'
                    radius='md'
                    placeholder='Write about your day here..'
                    c='black'
                    mb={30}
                />
                </div>
            </Stack>
            <div className={styles.stats}>
                <div className={styles.headerRow}>
                    <div className={styles.title}>Today's Metrics</div>
                    <Button
                        loading={updating}
                        type="submit"
                        bg='#001427'
                        h='32px'
                        ff='Zilla Slab'
                        fw={600}
                        fz='18px'
                        radius='sm'
                        leftSection={<FiCheckCircle size={16} color='#7fbbbd' />}
                        px={12}
                    >
                    Save
                    </Button>
                </div>
                <div className={styles.statGrid}>
                    <div className={`${styles.stat} ${styles.score}`}>
                        <div className={styles.statName}>score</div>
                        <div className={styles.statValue}>
                            <RingProgress
                                className={styles.low}
                                size={76}
                                thickness={6}
                                roundCaps
                                rootColor='#001427'
                                sections={[{ value: dayForm.values.totalScore, color: calculateScoreColor(dayForm.values.totalScore) }]}
                                label={
                                    <div className={`${styles.ringScore} ${styles.perfect}`}>
                                        {dayForm.values.totalScore}
                                    </div>
                                }
                                style={{ marginTop: '-2px' }}
                            />
                        </div>
                    </div>
                    
                    {dayForm.values.metrics.map((metric: IMetric, index: number) => {
                        let metricConfigMatch = userProfile.metricConfigurations.find(
                            (mc: IMetricConfiguration) => mc._id === metric.metricConfigurationId,
                        );

                        if(metricConfigMatch === undefined) 
                            return null;
                        return buildMetricTool(metric, metricConfigMatch, index);
                    }) }
                    {Array.from(Array(requiredSpacers)).map((_, index) => (
                        <div className={styles.spaceTaker} key={index}></div>
                    ))}
                </div>
            </div>
        </form>
        );

    }


export default DayForm;


// NOTE TO TOM
    // Since IMetric has an enforced _id, it must exist from the server side
    // therefore, need to dynamically make any updates to the current day when metric configurations are updated..


    /*const meldDayData = (dayData: IDay, user: any) => {

        let hybridData: IDay = {
            _id: dayData._id,
            date: dayData.date,
            journal: dayData.journal,
            totalScore: dayData.totalScore,
            metrics: [],
        };

        user?.metricConfigurations.forEach((metricConfig: IMetricConfiguration) => {

            let metricMatch = dayData.metrics.find(
                (metric: IMetric) => metric.metricConfigurationId === metricConfig._id,
            );

            if(metricMatch === undefined) {

                let id = metricConfig._id ? metricConfig._id : 'myId';

                hybridData.metrics.push({
                    metricConfigurationId: id,
                    value: 0,
                    completed: false,
                });
            }
            else {
                hybridData.metrics.push(metricMatch);
            }

        });

        return hybridData;

    }

    const hybridData = meldDayData(dayData, user);*/
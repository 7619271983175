// Libraries
import { Tabs, Stack, Image, Group, Text } from '@mantine/core';
import { FiActivity, FiSettings } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './NavMenu.module.css';

import nestIconBlue from '@/assets/nest-icon-blue.svg';
import nestIconGreen from '@/assets/nest-icon-green.svg';

export function NavMenu() {
    const navigate = useNavigate();
    let { pathname } = useLocation();

    return (
        <Tabs
            variant='pills'
            color='#EBE8E3'
            defaultValue='dashboard'
            value={pathname.split('/')[1]}
            onChange={(value) => navigate(`/${value}`)}
            radius='xl'
        >
            <Tabs.List bg='#1B3043' p='4px' className={styles.tabMenu} ff='Zilla Slab'>
                <Tabs.Tab value='dashboard' className={styles.homeTab} fz='24px' fw='600'>
                    <Group gap={'4px'}>
                        <Image
                            src={pathname === '/dashboard' ? nestIconBlue : nestIconGreen}
                            className={styles.nestIcon}
                        />
                        nest
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value='trends' className={styles.tab}>
                    <Stack align='center' gap={2}>
                        <FiActivity size={20} color='#7FBBBD' />
                        <Text size='15px' fw='700'>
                            trends
                        </Text>
                    </Stack>
                </Tabs.Tab>
                <Tabs.Tab value='settings' className={styles.tab}>
                    <Stack align='center' gap={2}>
                        <FiSettings size={20} color='#7FBBBD' />
                        <Text size='15px' fw='600'>
                            settings
                        </Text>
                    </Stack>
                </Tabs.Tab>
            </Tabs.List>
        </Tabs>
    );
}

export default NavMenu;

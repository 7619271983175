// Libraries
import { Image, Tooltip } from '@mantine/core';
import { FiSlash } from 'react-icons/fi';

// Styles
import styles from './MetricSelectors.module.css';
import { IMetricConfiguration } from '@/types/metrics';
import { IMetric } from '@/types/day.types';

// Images
import checkmark from '@/assets/metric-configs/mc-checkmark.png';
import { truncate } from './metricSelectors.utils';

// Interface
interface CheckmarkProps {
    metric: IMetric;
    metricConfiguration: IMetricConfiguration;
    onUpdate: (metric: IMetric, index: number) => void;
}

export function Checkmark({ metric, metricConfiguration, onUpdate }: CheckmarkProps) {


    const updateValue = () => {
        const updated = {
            ...metric,
            completed: true,
            value: metric.value === 0 ? 100 : 0
        };
        onUpdate(updated, metricConfiguration.order);
    }

    return (
        <div className={styles.metric} key={metric._id} >
            <Tooltip label={metricConfiguration.name} ff='Josefin Sans Variable' tt='uppercase' pt='8px' fz={12} fw={600} lts='1px' bg='#001427'>
                    <div className={styles.metricName}>{truncate(metricConfiguration.name)}</div>
                </Tooltip>
            <div className={styles.metricValue} onClick={updateValue}>
                { (metric.value === 0) ? <FiSlash size={46} color='#c8c5bf'/> : <Image src={checkmark} height='54px' /> }
            </div>
        </div>
    );
}

export default Checkmark;

import { ReactNode, useState } from 'react';
import { Popover, Image, UnstyledButton, Tooltip } from '@mantine/core';
import { FiSlash } from 'react-icons/fi';

// Styles
import styles from './MetricSelectors.module.css';

// Types
import { IMetric } from '@/types/day.types';
import { IMetricConfiguration } from '@/types/metrics';

// Images
import face1 from '@/assets/mc-emoji-smiley-1.png';
import face2 from '@/assets/mc-emoji-smiley-2.png';
import face3 from '@/assets/mc-emoji-smiley-3.png';
import face4 from '@/assets/mc-emoji-smiley-4.png';
import face5 from '@/assets/mc-emoji-smiley-5.png';
import triangle1 from '@/assets/mc-emoji-triangle-1.png';
import triangle2 from '@/assets/mc-emoji-triangle-2.png';
import triangle3 from '@/assets/mc-emoji-triangle-3.png';
import triangle4 from '@/assets/mc-emoji-triangle-4.png';
import triangle5 from '@/assets/mc-emoji-triangle-5.png';
import battery1 from '@/assets/mc-emoji-battery-1.png';
import battery2 from '@/assets/mc-emoji-battery-2.png';
import battery3 from '@/assets/mc-emoji-battery-3.png';
import battery4 from '@/assets/mc-emoji-battery-4.png';
import battery5 from '@/assets/mc-emoji-battery-5.png';

// Utils
import { truncate } from './metricSelectors.utils';



// Interface
interface PopoverEmojiProps {
    metric: IMetric;
    metricConfiguration: IMetricConfiguration;
    onUpdate: (metric: IMetric, index: number) => void;
}

interface IMetricMapping {
    value: number;
    icon: ReactNode;
}

interface EnumMetricMappings {
    [index: string]: IMetricMapping[];
}

const metricMappings: EnumMetricMappings = {
    'Emoji-Smiley' : [
        { 
            "value" : 0,
            "icon" : <FiSlash size={46} color='#c8c5bf' />
        },
        { 
            "value" : 20,
            "icon" : <Image src={face1} height='56px' w='auto' fit='contain' />
        },
        { 
            "value" : 40,
            "icon" : <Image src={face2} height='56px' w='auto' fit='contain'/>
        },
        { 
            "value" : 60,
            "icon" : <Image src={face3} height='56px' w='auto' fit='contain'/>
        },
        { 
            "value" : 80,
            "icon" : <Image src={face4} height='56px' w='auto' fit='contain'/>
        },
        { 
            "value" : 100,
            "icon" : <Image src={face5} height='56px' w='auto' fit='contain'/>
        }
    ],
    'Emoji-Battery' : [
        { 
            "value" : 0,
            "icon" : <FiSlash size={46} color='#c8c5bf' />
        },
        { 
            "value" : 20,
            "icon" : <Image src={battery1} height='62px' w='auto' fit='contain'/>
        },
        { 
            "value" : 40,
            "icon" : <Image src={battery2} height='62px' w='auto' fit='contain'/>
        },
        { 
            "value" : 60,
            "icon" : <Image src={battery3} height='62px' w='auto' fit='contain'/>
        },
        { 
            "value" : 80,
            "icon" : <Image src={battery4} height='62px' w='auto' fit='contain' />
        },
        { 
            "value" : 100,
            "icon" : <Image src={battery5} height='62px' w='auto' fit='contain'/>
        }
    ],
    'Emoji-Triangle' : [
        { 
            "value" : 0,
            "icon" : <FiSlash size={46} color='#c8c5bf' />
        },
        { 
            "value" : 20,
            "icon" : <Image src={triangle1} height='60px' w='auto' fit='contain'/>
        },
        { 
            "value" : 40,
            "icon" : <Image src={triangle2} height='60px' w='auto' fit='contain'/>
        },
        { 
            "value" : 60,
            "icon" : <Image src={triangle3} height='60px' w='auto' fit='contain'/>
        },
        { 
            "value" : 80,
            "icon" : <Image src={triangle4} height='60px' w='auto' fit='contain'/>
        },
        { 
            "value" : 100,
            "icon" : <Image src={triangle5} height='60px' w='auto' fit='contain'/>
        }
    ]
};

export function PopoverEmoji({ metric, metricConfiguration, onUpdate }: PopoverEmojiProps) {

    // Hooks
    const [opened, setOpened] = useState(false);


    // Functions

    const updateValue = (newValue: number) => {
        const updated = {
            ...metric,
            completed: true,
            value: newValue
        };
        onUpdate(updated, metricConfiguration.order);
        setOpened(false);
    }

    const activeMapping = metricMappings[metricConfiguration.metricType];
    const currentValue = metricMappings[metricConfiguration.metricType].find((mapping: IMetricMapping) => mapping.value === metric.value);

    

    return (
        <Popover key={metricConfiguration.name} position='top' withArrow={true} shadow='md' radius='md' opened={opened} onChange={setOpened}>
            <Popover.Target>
                <div className={styles.metric} key={metric._id} >
                    <Tooltip label={metricConfiguration.name} ff='Josefin Sans Variable' tt='uppercase' pt='8px' fz={12} fw={600} lts='1px' bg='#001427'>
                        <div className={styles.metricName}>{truncate(metricConfiguration.name)}</div>
                    </Tooltip>
                    <div className={styles.metricValue} onClick={() => setOpened((o) => !o)}>
                        { currentValue ? currentValue.icon : <FiSlash size={46} color='#c8c5bf' /> }
                    </div>
                </div>
            </Popover.Target>
            <Popover.Dropdown bg='#efede7' w='fit-content' className={styles.popoverDropdown}>
                <div className={styles.popoverOptions}>
                    {
                        activeMapping.map((mapping: IMetricMapping, index: number) => {
                            if(index > 0)
                                return (
                                    <UnstyledButton onClick={() => updateValue(mapping.value)} key={index}>{mapping.icon}</UnstyledButton>
                                )
                        })
                    }
                </div>
            </Popover.Dropdown>
        </Popover>
    );
}

export default PopoverEmoji;

// Libraries
import { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import { Menu, RingProgress, Title } from '@mantine/core';
import { DayPicker, CalendarDay, Modifiers } from "react-day-picker";
import "react-day-picker/style.css";

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn' // import locale

// Types
import { Month } from '@/types/day.types';
import { IServerError } from '@/types/errors.types';
import { getUserMonth } from '@/api/day.api';

// Styles
import styles from './NewCalendar.module.css';
import ErrorBox from '../errorbox/ErrorBox';

interface ErrorBoxProps {
    activeDate: dayjs.Dayjs;
    selectActiveDate: (values: any) => void;
}

interface ICustomDayProps {
    day: CalendarDay;
    modifiers: Modifiers;
}



export function NewCalendar({ activeDate, selectActiveDate}: ErrorBoxProps) {

    const [opened, setOpened] = useState(false);
    const [activeMonth, setActiveMonth] = useState(activeDate.format('YYYY-MM'));

    // isPending: isMonthPending, isError: isMonthError, 
    const {data: monthData, error: monthError } = useQuery<Month, IServerError>({ 
        queryKey: ['user-month', activeMonth], 
        queryFn: () => getUserMonth(activeMonth),
        retry: 1,
    });


function CustomDay({day, modifiers}: ICustomDayProps) {
    //const buttonRef = useRef<HTMLButtonElement>(null);
    //const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);
  
    // From here returns whatever you want, but the logic should be the same:
    /*if (dayRender.isHidden) {
      return <div role="gridcell"></div>;
    }
    if (!dayRender.isButton) {
      return <div {...dayRender.divProps} />;
    }*/
   console.log('mods', modifiers);

   const totalScoreColors = ['#596B7B', '#F85A5A', '#F4A15B', '#7FBBBD'];

    const calculateScoreColor = (score: number) => {
        if(score >= 80) return totalScoreColors[3];
        else if (score >= 50) return totalScoreColors[2];
        else if (score >= 25) return totalScoreColors[1];
        else return totalScoreColors[0];
    }

   const dayMatch = monthData?.days.find(d => d.date === dayjs(day.date).format('YYYY-MM-DD'));

    return (
        <td role="gridcell" className="rdp-day" data-day={day.date}>
            <button className={`${'rdp-day_button'} ${modifiers.today || modifiers.selected ? styles.modified : ''}`} tabIndex={0} aria-label={dayjs(day.date).format('dddd, MMMM D, YYYY')} onClick={() => handleUpdate(day.date)}>
                <RingProgress
                size={44}
                thickness={4}
                roundCaps
                rootColor='#001427'
                sections={[{ value: dayMatch ? Number(dayMatch?.totalScore) : 100, color: dayMatch ? calculateScoreColor(dayMatch.totalScore) : 'var(--blue-shade-max)' }]}
                label={
                <div className={`${styles.ringScore}`}>
                    {dayjs(day.date).format('DD')}
                </div>
                }
            />
            </button>
        </td>
    );
    
  }

    /*useEffect(() => {
        console.log(monthData);
    }, [monthData]);*/

    const realDate = activeDate.toDate();

    const handleUpdate = (date: Date | undefined) => {

        const formatted = dayjs(date);

        selectActiveDate(formatted);
        close();
    }

    const switchMonth = (month: Date) => {
        setActiveMonth(dayjs(month).format('YYYY-MM'));
    }

    if(monthError)
        return <ErrorBox text={monthError.message} serverError={monthError}/>;

    return (
        <Menu shadow="sm" opened={opened} onChange={setOpened} transitionProps={{ transition: 'pop', duration: 120 }}>
            <Menu.Target>
                <Title
                    bg='#B5AFA5'
                    c={'white'}
                    fz={{ base: '18px', xs: '21px', sm: '24px', md: '26px', lg: '30px' }}
                    ff='Zilla Slab'
                    fw={600}
                    ta='center'
                    py='6px'
                    px='12px'
                    w='fit-content'
                    style={{cursor: 'pointer', borderRadius: '4px', display: 'flex', alignItems:'center', gap: '8px'}}
                >
                    {activeDate.format('dddd, MMMM D')}{' '}
                    <FiChevronDown size={22} color='#eae6df' />
                </Title>
                
            </Menu.Target>
  
            <Menu.Dropdown p={20} style={{borderRadius: '6px', borderColor: 'transparent', zIndex: '1000'}} bg='var(--blue-shade)' >
                <DayPicker
                    mode="single"
                    selected={realDate}
                    onSelect={handleUpdate}
                    onMonthChange={switchMonth}
                    components={{ 
                        Day: CustomDay 
                      }} 
                />
            </Menu.Dropdown>
      </Menu>
    );
}

export default NewCalendar;

// Auth
import { getToken } from '@/auth/auth.store';

export const getApiUrl = () => {
    const host = window.location.hostname;
    if (host?.includes('trycrow.ca')) 
        return 'https://trycrow.ca';

    return '';
};

export const buildHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
};

export const buildResponse = (success: boolean, data: any | null, message: string) => {
    return {
        success: success,
        data: data,
        message: message,
    };
};

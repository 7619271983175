import axios from 'axios';

// Utils
import { ISignupParams, ILoginParams } from '@/types/auth.types';

// Functions
export const signup = async ({email, password, name}: ISignupParams) => {

    const { data } = await axios.post('/api/v1/users/signup', { email, password, name });
    return data.data;

};

export const login = async ({email, password}: ILoginParams) => {

    const { data } = await axios.post('/api/v1/users/login', { email, password });
    return data.data;

};
import { create, useStore } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { persist, createJSONStorage } from 'zustand/middleware';
import { jwtDecode } from 'jwt-decode';

import { IToken, UserProfile } from '@/types/auth.types';


interface IAuthStore {
    token: string | null;
    tokenData: IToken | null;
    tokenExpired: boolean;
    user: UserProfile | null;
    actions: {
        setToken: (token: string | null) => void;
        init: () => void;
        clearToken: () => void;
    }
}

export const decodeToken = (token: string): IToken => {
    return jwtDecode<IToken>(token);
};

const authStore = create<IAuthStore>()(
    persist(
        (set, get) => ({
            token: null,
            tokenData: null,
            tokenExpired: false,
            user: null,
            actions: {
                setToken: (token: string | null) => {
                    const tokenData = token ? decodeToken(token) : null;
                    set({ token, tokenData, tokenExpired: false, user: tokenData?.user });
                },
                init: () => {
                    const { setToken } = get().actions;
    
                    const cached = localStorage.getItem('crow-auth-store');
                    setToken(cached ? JSON.parse(cached).token : null);
                },
                clearToken: () => {
                    set({ token: null, tokenData: null, tokenExpired: false, user: null });
                },
            }
        }),
        {
            name: 'crow-auth-store', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
            partialize: (state) => {
                const { actions, ...rest } = state;
                return rest;
            }
        },
    ),
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof authStore, U>>;


// Selectors
const tokenSelector = (state: ExtractState<typeof authStore>) => state.token;
const tokenDataSelector = (state: ExtractState<typeof authStore>) => state.tokenData;
const tokenExpiredSelector = (state: ExtractState<typeof authStore>) => state.tokenExpired;
const userSelector = (state: ExtractState<typeof authStore>) => state.user;
const actionsSelector = (state: ExtractState<typeof authStore>) => state.actions;

// Getters
export const getToken = () => tokenSelector(authStore.getState());
export const getTokenData = () => tokenDataSelector(authStore.getState());
export const getTokenExpired = () => tokenExpiredSelector(authStore.getState());
export const getUser = () => userSelector(authStore.getState());
export const getActions = () => actionsSelector(authStore.getState());

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(authStore, selector, equalityFn);
}

// Hooks
export const useToken = () => useAuthStore(tokenSelector);
export const useTokenData = () => useAuthStore(tokenDataSelector);
export const useTokenExpired = () => useAuthStore(tokenExpiredSelector);
export const useUser = () => useAuthStore(userSelector);
export const useActions = () => useAuthStore(actionsSelector);

// Non-hook function
export const updateTokenExpired = () => authStore.setState(() => ({ tokenExpired: true }));